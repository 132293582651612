@charset "UTF-8";
// ==========================================================================
// Foundation
// ==========================================================================
@import "./foundation/_mixin";

/* タイムライン
------------------------------------------------------*/

.timelineCol-wrap {
  margin-top: 35px;

  &:first-child {
    margin-top: 0;
  }

  .likebtnCol:last-child {
    border-bottom: none;
  }
}

.tlname {
  margin-bottom: 15px;

  dt {
    width: 15%;
    display: block;
    float: left;
    margin-right: 3%;

    &.inpage {
      width: 10%;
    }

    img {
      width: 100%;
    }
  }

  dd.name {
    width: 79%;
    float: left;
  }
}

.tlimgCol {
  display: inline-block;
  width: 100%;
  font-size: 0;
  margin: 10px 0;

  a {
    margin-bottom: 2%;

    img {
      width: 100%;
    }
  }
}

/* one item */

.tltopCol .tlimgCol a {
  &:nth-last-child(1) {
    width: 100%;
  }

  &:first-child {
    &:nth-last-child(2), &:nth-last-child(3), &:nth-last-child(4), &:nth-last-child(5) {
      width: 49%;
      display: inline-block;
      font-size: 0;

      ~ a {
        width: 49%;
        display: inline-block;
        font-size: 0;
      }
    }
  }
}

/* two items */

.tlimgCol a:nth-child(odd) {
  margin-right: 2%;
}

.tltour {
  border: 1px solid #ddd;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  padding: 15px;
  margin-bottom: 10px;
  box-sizing: border-box;
}

.tltopCol {
  li {
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .tltour:last-child {
    margin-bottom: 0;
  }
}

.tltour {
  dl dt {
    font-weight: bolder;
    line-height: 150%;
    display: table-cell;
    vertical-align: middle;

    &:first-child {
      border-right: 1px solid #ddd;
      padding-left: 0;
    }

    img {
      padding-right: 15px;
    }
  }

  dt {
    padding-left: 15px;

    &:nth-child(2) + dd {
      border-top: 1px solid #ddd;
      padding-top: 15px;
      margin-top: 15px;
    }
  }
}

.tltag {
  text-align: right;

  dt {
    display: inline-block;
  }

  dd {
    display: inline-block;
    background: #21BEAD;
    padding: 4px 8px 2px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    text-align: center;
    font-weight: bolder;
    text-decoration: none;
    line-height: 100%;
    color: #fff;
    margin-left: 5px;
  }

  dt:first-child + dd {
    margin-left: 0;
  }
}

.likebtnCol {
  border-top: 1px dashed #ddd;
  border-bottom: 1px solid #ddd;
  padding: 15px 0 20px;
  margin-top: 10px;
  box-shadow: 0px 5px 6px -3px #ccc;
  -moz-box-shadow: 0px 5px 6px -3px #ccc;
  -webkit-box-shadow: 0px 5px 6px -3px #ccc;

  li {
    float: left !important;
    width: auto !important;
    font-weight: bolder;

    &:last-child {
      float: right !important;
    }
  }
}

.like-inner {
  width: 22px;
  height: 22px;
  background: #21BFAE;
  display: inline-block;
  overflow: hidden;
  border-radius: 11px;
  -webkit-border-radius: 11px;
  -moz-border-radius: 11px;
  vertical-align: sub;
  margin-right: 5px;

  .like-h {
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -ms-transform: scale(0.8);
    -o-transform: scale(0.8);
    transform: scale(0.8);
    margin: 4px 0 0 1px;
  }
}

.like-h {
  position: relative;
  width: 25px;
  height: 22.5px;
  display: inline-block;
  vertical-align: middle;
  margin-top: 5px;

  &:before, &:after {
    position: absolute;
    left: 9px;
    top: 0;
    content: "";
    width: 9px;
    height: 14px;
    background: #21BFAE;
    -moz-border-radius: 8px 8px 0 0;
    border-radius: 8px 8px 0 0;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transform-origin: 0 100%;
    -moz-transform-origin: 0 100%;
    -ms-transform-origin: 0 100%;
    -o-transform-origin: 0 100%;
    transform-origin: 0 100%;
  }
}

.like-inner .like-h {
  &:before, &:after {
    background: #FFF;
  }
}

.like-h:after {
  left: 0;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transform-origin: 100% 100%;
  -moz-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  -o-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}

.btn_like {
  cursor: pointer;
  font-weight: bolder;
  font-size: 90%;
}

.timelineDetailCol {
  width: 700px;
  margin: 0 auto;
}

.shopdetailCol .timelineShopCol li {
  float: none;
  width: 100%;
  margin-left: 0;
}

.timelineShopCol {
  border: 2px solid #21bead;
  border-radius: 10px;
  padding: 15px;
  margin-left: 2%;
  width: 49%;
  box-sizing: border-box;
}

.detail .timelineShopCol {
  width: 100% !important;
  margin-left: 0;

  .jspPane {
    width: 96% !important;
  }
}

#new_data {
  margin-top: 35px;
}

.timelineCol-inner .jspPane {
  width: 96% !important;
}

.timelineCol {
  .cntCol {
    padding: 20px 15px 20px 20px;
  }

  .viewall {
    border-top: 1px solid #ddd;
    margin-top: 10px;
  }
}

.timelineShopCol .viewall {
  border-top: 1px solid #ddd;
  margin-top: 10px;
}

.shopNavi {
  display: none;
}

@include mediaSp {
  .twoCol.timelineCol {
    margin-right: 0;
  }

  .timelineCol .cntCol {
    padding: 0;
  }

  .tltour {
    dl dt img {
      display: none;
    }

    dt {
      padding-left: 0;
    }

    dl dt:first-child {
      border: none;
    }
  }

  .timelineDetailCol {
    width: 100%;
  }

  .timelineShopCol {
    margin: 0;
    padding: 0;
    border: none;
  }

  .shopNavi {
    border-bottom: 2px solid #ddd;
    margin: 20px 0 0 0;

    li {
      width: 49%;
      float: left;
      margin-right: 2%;

      &:last-child {
        margin-right: 0;
      }

      a {
        background: #eee;
        color: #444;
        -webkit-border-bottom-left-radius: 0;
        -webkit-border-bottom-right-radius: 0;
        -moz-border-radius-bottomleft: 0;
        -moz-border-radius-bottomright: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

      &.current a {
        background: #FFE643;
        color: #403000;
      }

      a .arrowCol {
        background: #777;

        &:after {
          border-top: 2px solid #fff;
          border-right: 2px solid #fff;
        }
      }

      &.current a .arrowCol {
        background: #403000;

        &:after {
          border-top: 2px solid #FFE643;
          border-right: 2px solid #FFE643;
        }
      }
    }
  }

  .cnt-tour h4 {
    border: none !important;
    padding-top: 0 !important;
    margin-top: 0 !important;
  }

  .tltag {
    text-align: left;
  }
}